import React, { useRef } from "react"
import { motion } from "framer-motion"
import { ContextBasedStoreIconsActions } from "./cta"
import * as ReactMobileDetect from "react-device-detect"


export class ContextBasedImage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isAndroid: false,
      isIOS: false,
    }
  }

  componentDidMount() {
    this.setState({
      isAndroid: ReactMobileDetect.isAndroid,
      isIOS: ReactMobileDetect.isIOS,
    })
  }

  render() {
    const { isAndroid, isIOS } = this.state;
    const { images } = this.props;

    if (images.length < 0) {
      return;
    }

    let useSource;
    const _default = images.default ? images.default : false;
    const _android = images.android && isAndroid ? images.android : false;
    const _ios = images.ios && isIOS ? images.ios : false;

    if (_android || _ios) {
      useSource =  (_android) ? _android : _ios;
    } else {
      useSource = _default;
    }

    const animate = {
      top: 0,
      transition: {
        delay: 1.7,
        duration: 1,
      },
    };

    if (useSource) {
      return <motion.img  animate={animate} initial={{top: '100%'}} src={useSource.url} alt={"Betcenter Mobile App"} className={`c-hero__img `} />
    }
    return '';
  }
}

const Hero = ({
  title = '',
  actions = false,
  label = '',
  images,
  ...attrs
}) => {

  const ref = useRef();
  return (
    <div className={`c-hero c-hero--animate c-hero--${label}`} {...attrs}>
      <div className="c-hero__wrapper">
        <div className="o-container">
          <div className="c-hero__content">
            <h1 className={"c-hero__title [ u-h1 ]"}><span>{title}</span></h1>
            {actions && (
              <footer className="c-hero__footer">
                <ContextBasedStoreIconsActions className="c-hero__actions" />
              </footer>
            )}
          </div>
        </div>
      </div>
      <ContextBasedImage images={images} ref={ref} />
    </div>
  )
}

export default Hero;
