import React from "react"
import appstore from "../../images/appstore.svg"
import googleStore from "../../images/android.svg"
import { motion, useTransform, useViewportScroll } from "framer-motion"
import * as ReactMobileDetect from 'react-device-detect';

const AppleIcon = () =>
  <a href={"https://apps.apple.com/be/app/betcenter/id1167984518"} target="_blank" rel="noopener noreferrer"><img src={appstore} alt="App Store"/></a>

const AndroidIcon = () =>
  <a href={"https://downloads.cashpoint.services/apk/betcenter_be.apk"} target="_blank" rel="noopener noreferrer"><img src={googleStore} alt="Google Store 2"/></a>

export class ContextBasedStoreIconsActions extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isAndroid: false,
      isIOS: false,
    }
  }

  componentDidMount() {
    this.setState({
      isAndroid: ReactMobileDetect.isAndroid,
      isIOS: ReactMobileDetect.isIOS
    })
  }

  render() {
    const { isAndroid, isIOS } = this.state;
    const {className} = this.props;

    if (isAndroid) {
      return <div className={`c-actions [ ${className} ]`}><AndroidIcon/></div>
    }
    if (isIOS) {
      return <div className={`c-actions [ ${className} ]`}><AppleIcon/></div>
    }
    return <div className={`c-actions [ ${className} ]`}><AppleIcon/><AndroidIcon/></div>
  }

}

const CallToAction = ({
  title = '',
  actions = false,
}) => {
  const { scrollYProgress } = useViewportScroll();
  const opacity = useTransform(scrollYProgress, [0, .95], [0, 1]);
  const scale = useTransform(scrollYProgress, [.5, 1  ], [1, 0]);
  return (
    <div className="c-cta c-cta--app-icon">
      <motion.div className="c-cta__wrapper" style={{opacity}}>
        <div className="c-cta__content">
          <h1 className={"c-cta__title [ u-h1 ]"}><span>{title}</span></h1>
          {actions && (
            <ContextBasedStoreIconsActions className="c-cta__actions" />
          )}
        </div>
        <motion.div className={"c-cta__reveal"} style={{ scaleX: scale }} />
      </motion.div>
    </div>
  )
}

export default CallToAction;
