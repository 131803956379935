import React from "react"

import { useStaticQuery, graphql } from "gatsby"


const ShopsDropdown = () =>  {

  const { allPrismicLocation } = useStaticQuery(
    graphql`
      query {
        allPrismicLocation(sort: {fields: data___city}) {
    nodes {
      data {
        city
        zip
        adress {
          raw {
            text
          }
        }
      }
    }
  }
      }
    `
  )

  return <select name="shop">
    {allPrismicLocation.nodes.map(({data}, i) => {
      return <option value={`${data.zip} ${data.city} – ${data.adress.raw[0].text}`} key={i}>{data.zip} {data.city} – {data.adress.raw[0].text}</option>
    })}
  </select>
}

export default ShopsDropdown;
