import React, { useState } from "react"
import { RichText } from "prismic-reactjs"
import { htmlSerializer } from "../../utilities/htmlSerializer"
import ShopsDropdown from "./ShopDropdown"
import { useTranslation } from "react-i18next"

const Label = ({text, required, children}) => {
  const { t } = useTranslation('general');
  return <div className={"o-form__item"}>
    <label>{text} {required && (<abbr title={t('required')}>*</abbr>)}</label>
    {children}
  </div>
}


const TextField = ({name, required, type="text"}) =>
  <input type={type} name={name} required={required}/>

const EmailField = ({name, required}) =>
  <TextField name={name} required={required} type="email" />

const Button = ({name}) =>
  <div className={"o-form__item"}>
    <button type={"submit"} className={"o-button"}>{name}</button>
  </div>

const RadioItem = ({name, label, required}) =>
  <div className={"o-form__item o-form__item--checkbox"}>
    <label><input type={"radio"} name={name} value={label} required={required} />{label}</label>
  </div>

const CheckboxItem = ({name, label, required, onChange, onInvalid}) => {
  return <div className={"o-form__item o-form__item--checkbox"}>
    <label><input type={"checkbox"} name={name} required={required} onInvalid={onInvalid} onChange={(e) => {
    e.target.setCustomValidity('')
      onChange(e);
    }
    } />{label}</label>
  </div>
}

const Radiobutton = ({name, required, label, options}) => {
  const optionItems = options && options.length === 1 ? options[0].text.split("\n") : null;
  return <div className={"o-form__item"}>
    {optionItems &&
    <div>
      <Label text={label} required={required} />
      {optionItems.map((key, i) =>
        <RadioItem label={key} name={name} required={required} key={i}  />
      )}
    </div>
    }
  </div>
}

const Checkbox = ({name, required, label, options}) => {
  const optionItems = options && options.length === 1 ? options[0].text.split("\n") : null;
  const [isRequired, setIsRequired]  = useState(required);
  // Keep checkbox checked state for all options
  const [checked, setChecked]  = useState([]);
  // Only update CustomValidity if the isRequired value changed
  const [requiredHasChanged, setRequiredHasChanged]  = useState(false);
  const { t } = useTranslation('general');
  return <div className={"o-form__item"}>
    {optionItems &&
    <div>
      <Label text={label} required={required} />
      {optionItems.map((key, i) =>
        <CheckboxItem label={key} name={`${name} ${key}`} key={i} required={isRequired}
                      onInvalid={(e) => {
                        if (!required || !requiredHasChanged) { return; }
                        e.target.setCustomValidity(isRequired ? t('selection') : '')
                      }}
                      onChange={(e) => {
                        if (!required) { return; }
                        const v = [...checked];
                        v[i] = e.target.checked;
                        const noOptionChecked = v.filter(c => c).length === 0;
                        setRequiredHasChanged(noOptionChecked !== requiredHasChanged);
                        setChecked(v);
                        setIsRequired(noOptionChecked);
                      } } />
      )}
    </div>
    }
    {!optionItems &&
    <label><input type={"checkbox"} name={name} required={required}/>{label}</label>
    }
  </div>
}

const Form = ({primary, fields, success, path}) => {
  const {introduction, success: success_message, name} = primary;

  if (success) {
    return <div className={"o-form"}>
      <div className={"o-form__introduction"}>
        <RichText render={success_message}  htmlSerializer={htmlSerializer} />
      </div>
    </div>
  }
  return <form className={"o-form"} name={name} action={path} method={"post"}  netlify-honeypot="bot-field" data-netlify="true">

    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value={name} />

    {introduction && (
      <div className={"o-form__introduction"}>
        <RichText render={introduction}  htmlSerializer={htmlSerializer} />
      </div>
    )}
    {fields.map(({type, label, name, required, options}, i) => {
      switch (type) {
        case "text":
          return <Label text={label} key={i}  required={required}>
            <TextField name={name} required={required} />
          </Label>
        case "email":
          return <Label text={label} key={i} required={required}>
            <EmailField name={name} required={required} />
          </Label>
        case "checkbox":
          return <Checkbox label={label} required={required} name={name} key={i} options={options}/>

        case "radiobutton":
          return <Radiobutton label={label} required={required} name={name} key={i} options={options}/>
        case "shop-dropdown":
            return  <Label text={label} key={i} required={required}>
              <ShopsDropdown key={i} />
            </Label>
        case "button":
          return <Button name={name || label} key={i} />
        default:
          return <div key={i}>{type}</div>
      }
    })}
  </form>
}
export default Form;
