import React from "react"

/**
 * Image component
 * Full width image callout with caption
 * @property {string} src
 * @property {string} caption
 */
export default function Icon({
                               src = '',
                               caption = '',
                               className,
                               ...attrs
                             }) {
  return (
      <img src={src} alt={caption} className={className || ''} {...attrs} />
  );
}
