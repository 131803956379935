import React, { useEffect, useState } from "react"
import { RichText } from "prismic-reactjs"
import { htmlSerializer } from "../utilities/htmlSerializer"
import ShopsMap from "./shopsmap"
import { Autocomplete, useLoadScript } from "@react-google-maps/api"
import backIcon from "../../images/back.svg"
import { headingDistanceTo } from "geolocation-utils"
import mapIcon from "../../images/map-icon.svg"
import locationIcon from "../../images/location.svg";
import { useTranslation } from 'react-i18next';
import Prismic from "prismic-javascript"

const ShopsPage = ({ primary, language }) => {

  const [autocomplete, setAutocomplete] = useState(null);
  const [current, setCurrent] = useState(null);
  const filters = ['All', 'Open', 'Soon', 'Closed'];
  const [filter, setFilter] = useState(filters[0]);
  let _today = new Date().getDay();
  const [viewMoreItems, setViewMoreItems] = useState([]);
  const { t } = useTranslation('shops');

  const [locations, setLocData] = React.useState(null)
  const [loading, setLoading] = useState(true);

  const apiEndpoint = 'https://promo-betcenter-be.cdn.prismic.io/api/v2'
  const accessToken = '' // This is where you would add your access token for a Private repository

  const Client = Prismic.client(apiEndpoint, { accessToken })

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at('document.type', 'location'),
        { pageSize: 100, lang: 'nl-be' }

      )
      if (response) {
        setLocData(response.results)
        setLoading(false);
      }
    }
    fetchData()
  }, []);

  /**
   * @param autocomplete
   */
  const onAutoCompleteLoad = data => {
    if (!autocomplete) {
      geoLocate();
    }
    setAutocomplete(data);
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyC1u6TVh53XrJtgKZ6VKFoTOhyaQQAVz9s",
    language: language,
    libraries: ['places'],
  })

  /**
   * Fetch the location from the browser
   */
  const geoLocate = () => {
    if (navigator.geolocation && !current) {
      navigator.geolocation.getCurrentPosition(setCurrentLocation);
    }
  }

  const filteredLocations = () => {
    if (filter !== 'All') {
      return locations.filter(item => item.data.status === filter);
    }

    locations.map(obj => {
      return Object.assign(obj.data, {
        distance: headingDistanceTo(obj.data.geo, current?.geo).distance,
      });
    });

    locations.sort((a, b) => {
      return a.data.distance - b.data.distance;
    });
    return locations;
  }

  const setCurrentLocation = (data) => {
    const coords = {
      lat: data.coords.latitude,
      lng: data.coords.longitude,
    }
    setCurrent({ geo: coords, city: t('current location') });
  }

  /**
   */
  const changeLocation = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place) {
        const zip = place.address_components.find(item => item.types.includes('postal_code'));
        const city = place.address_components.find(item => item.types.includes('locality'));
        setCurrent({
          geo: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
          zip: zip?.long_name || null,
          city: city?.long_name || null,
        })
      }
    }
  };

  const resetCurrent = () => {
    setCurrent(null);
  }

  const toggleViewMoreItems = (index) => {
    // add to the toggle list if item not found
    if (viewMoreItems.indexOf(index) === -1) {
      setViewMoreItems(viewMoreItems => [...viewMoreItems, index])
    } else {
      setViewMoreItems([viewMoreItems.filter(item => item !== index)]);
    }
  }

  /*
    Return current opening hours
   */
  const getCurrentOH = (items) => {
    if (_today === 0) {
      _today = 7;
    }
    return items.filter((item, idx) => idx + 1 === _today)[0];
  }

  const isActive = (index) => {
    return viewMoreItems.indexOf(index) !== -1;
  }

  return (isLoaded && loading === false) ? (
    <div className="c-shops [ o-section ]">
      <div className="o-sidebar">
        <div className="o-sidebar__container">
          {!current ? (
            <>
              <div className="o-sidebar__header">
                <RichText render={primary.shop_content} htmlSerializer={htmlSerializer} />
              </div>
              <div className="o-sidebar__main">
                <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
                  <label htmlFor="zip">{t('label')}</label>
                  <Autocomplete options={{ types: ['(regions)'] }} onLoad={onAutoCompleteLoad} onPlaceChanged={changeLocation} restrictions={{ country: "BE" }}>
                    <div className="o-form__input">
                      <input id={"zip"} type={"text"} placeholder={""} />
                      <img className={"o-form__input-after"} src={locationIcon} alt={"Locate"} onClick={() => geoLocate()} />
                    </div>
                  </Autocomplete>
                  <button className="o-button [ c-shops__button ]" type="submit">{t('button')}</button>
                </form>
              </div>
            </>
          ) :
            <>
              <div className="o-sidebar__header">
                <h1 className="u-h2"><span onClick={resetCurrent}><img src={backIcon} alt="" /></span> {current.zip || ''} {current.city || ''}</h1>

                <ul className="c-filters o-sidebar__section">
                  {filters.map((item, idx) => <li key={idx} className={`o-filter ${filter === item ? 'o-filter--active' : ''}`} onClick={() => setFilter(item)}>{t(`filters.${item}`)}</li>)}
                </ul>
              </div>
              <div className="o-sidebar__main">
                {filteredLocations().map((item, idx) =>
                  <div key={idx} className="o-card">
                    <RichText render={item.data.adress} />
                    <p className={"u-mb"}>{item.data.zip} {item.data.city}</p>
                    <p className={`u-mb--s o-status o-status--${item.data.status.toLowerCase()}`}>{
                      item.data.status_text ?
                        <>{item.data.status_text}</>
                        :
                        <>{t(`filters.${item.data.status}`)}</>
                    }</p>
                    {(item.data.status === 'Open' && item.data.openinghours) &&
                      <div className="o-card-hours">
                        <div className={"o-card-hours__item"}>
                          {getCurrentOH(item.data.openinghours).closed ? t('closed') : <>{getCurrentOH(item.data.openinghours).start_hour}-{getCurrentOH(item.data.openinghours).end_hour}</>}

                          <div className={"o-card-hours__item-value"}>
                            <span className={"o-link o-link--mini o-link--toggle"} onClick={() => toggleViewMoreItems(idx)}>{isActive(idx) ? t('show less') : t('show more')}</span>
                          </div>
                        </div>
                        {(viewMoreItems.indexOf(idx) !== -1) && (
                          <ul>
                            {item.data.openinghours.map((oh, index) =>
                              <div className={"o-card-hours__item"} key={index}><span>{t(`days.${oh.day}`)}</span>
                                <div className={"o-card-hours__item-value"}>
                                  {oh.closed ? t('closed') : <>{oh.start_hour}-{oh.end_hour} </>}
                                </div>
                              </div>
                            )}
                          </ul>
                        )}

                      </div>
                    }
                    <a href={`http://www.google.com/maps/place/${item.data.geo.latitude},${item.data.geo.longitude}`} target={"_blank"} className="o-card__link-map"> <img src={mapIcon} alt={""} /> {t('map')}</a>
                  </div>
                )}
              </div>
            </>
          }

        </div>
      </div>
      <div className="o-content">
        <ShopsMap current={current} onMarkerPress={setCurrent} markers={locations} />
      </div>

    </div>) : <div className={"o-loading o-loading--full"}>Loading...</div>
}

export default ShopsPage;
