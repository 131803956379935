import React from "react"
import { RichText } from "prismic-reactjs"
import { htmlSerializer } from "../utilities/htmlSerializer"

const Footer = ({language, disclaimer}) => (
  <footer className={`c-footer`}>

    {disclaimer && (
      <div className="c-meta [ o-container ]" id={"disclaimer"}>
        <RichText render={disclaimer} htmlSerializer={htmlSerializer} />
      </div>
    )}

    <div className="c-footer__container [ o-container ]">
      <ul className={"c-footer__menu"}>
        <li><a target={"_blank"} href={`https://www.betcenter.be/${language}/page/terms`} rel="noopener noreferrer">Terms & Conditions</a></li>
        <li><a target={"_blank"} href={`https://www.betcenter.be/${language}/page/data_privacy_statement`} rel="noopener noreferrer">Privacy Policy</a></li>
      </ul>
    </div>
  </footer>
)

export default Footer;
