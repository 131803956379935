import React from "react"
import { Elements } from "prismic-reactjs"
import CountUp from 'react-countup';

// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
export const htmlSerializer = function(type, element, content, children, key) {
  var props = {};
  children = children.map((item,i) => {
    if (item?.type === "span") {
      return <CountUp key={i}  end={parseFloat(item.props.children[0])}/>
    }
    return item;
  })

  switch(type) {
    case Elements.heading1: // Heading 1
      props = {className: 'u-h1'};
      return React.createElement('h1', propsWithUniqueKey(props, key), children);
    case Elements.heading2: // Heading 2
      props = {className: 'u-h2'};
      return React.createElement('h2', propsWithUniqueKey(props, key), children);
    case Elements.paragraph: // Heading 1
      props = {};
      return React.createElement('p', propsWithUniqueKey(props, key), children);

    // Return null to stick with the default behavior
    default:
      return null;
  }
};
