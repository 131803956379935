import React from "react"
import logo from "../../images/betcenter.svg"
import { Link } from "gatsby"

const Header = ({
  languages = [],
  current = {}
}) => {
  return (
    <header className="o-page__header [ c-menu ]">

      <a href={"https://betcenter.be"} className={"o-logo"}><img src={logo} alt="Betcenter" className="o-logo__img" /></a>

      {languages.length >= 1 &&
      <ul className="o-dropdown">
        <li>{current}</li>
        <ul>
          {languages.map(({ lang, path }, i) =>
            <li className="o-dropdown__item" key={i}>
              <Link className="o-dropdown__item-link" to={`${lang}/${path}`}>{lang}</Link>
            </li>
          )}
        </ul>
      </ul>
      }
    </header>
  )
}

export default Header;
