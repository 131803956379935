import React, { useEffect, useState } from "react"
import { motion, useAnimation, useViewportScroll } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { RichText } from "prismic-reactjs"
import Icon from "./icon"
import classNames from 'classnames';

const ListItem = ({data, index}) => {
  const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '100px'  })
  const controls = useAnimation();
  const [pageScrolled, setPageScrolled] = useState(false);
  const { scrollYProgress } = useViewportScroll()
  if (index === 0) {
    scrollYProgress.onChange(() => setPageScrolled(true))
  }
  useEffect(() => {
    if (inView) {
      controls.start({
        y: 0,
        opacity: 1,
        transition: { duration: 1, delay: index === 0 ? 3 : 0 },
      })
    }
  }, [inView])

  useEffect(() => {
    if (index === 0) {
      controls.start({
        y: 0,
        opacity: 1,
        transition: { duration: 1, delay: 0 },
      })
    }
  }, [pageScrolled])

  return (
    <motion.li animate={controls} initial={{y: '120%', opacity: 0}} ref={ref} className="o-list-item [ c-list__item ]" >
      {data.icon && (
        <Icon src={data.icon.url} alt={data.icon.alt} className="o-list-item__icon" />
      )}
      <h2 className="o-list-item__title">{data.title}</h2>
      <div className="o-list-item__text">
        <RichText render={data.content} />
      </div>
    </motion.li>
  )
}

const List = ({
  items,
  label
}) => {
  const classes = classNames('c-list o-container',
    {
      'c-list--block-link': label === 'block_link'
    }
  )
  return (
    <motion.ul className={classes}>
      {items.map((item, key) =>
        <ListItem key={key} index={key} data={item}/>
      )}
    </motion.ul>
  )
}

export default List;
