import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Header from "./components/header"
import Hero from "./components/hero"
import List from "./components/list"
import Footer from "./components/footer"
import CallToAction from "./components/cta"
import Shops from "./components/shops"
import { useTranslation } from "react-i18next"
import backIcon from "../images/back.svg"
import Form from "./components/page/Form"

require('../2020/index.scss');

export const query = graphql`
query PromoPageQuery($uid: String!, $lang: String!) {
  prismic {
    page(uid: $uid, lang: $lang) {
      page_title
      meta_description
      meta_image
      body {
        ... on PRISMIC_PageBodyHero {
          type
          label
          primary {
            title
            show_applinks
            image_android
            image_ios
            image_default
          }
        }
        ... on PRISMIC_PageBodyShops {
          type
          label
          primary {
            shop_content
          }
        }
        ... on PRISMIC_PageBodyList {
          type
          label
          fields {
            content
            icon
            title
          }
        }
        ... on PRISMIC_PageBodyForm {
          type
          label
          fields {
            label
            name
            required
            options
            type
          }
          primary {
            introduction
            success
            name
          }
        }
      }
      disclaimer
    }
    allPages(uid: $uid) {
      edges {
        node {
          _meta {
            lang  
            uid
          }
        }
      }
    }
  }
}
`

const IndexPage = props => {
  const { i18n, t } = useTranslation('general');
  const {pageContext, location} = props;
  let hasReferrer = false;

  const [data] = useState(props.data.prismic);

  i18n.changeLanguage(pageContext.lang);
  if (!pageContext.uid) return null;

  const appMeta = [
    {
    name: 'apple-itunes-app',
    content: `app-id=1167984518`,
    },
    {
      name: 'og:image',
      content: data.page?.meta_image ? data.page.meta_image.url : ''
    }
  ];

  const curLanguage = location.pathname.split('/').filter(String)[0]; // filter removes empty strings
  const languages = data.allPages.edges
    .map(({node}) => ({lang: node._meta.lang.substring(0,2), path: node._meta.uid}) )
    .filter(item => item.lang !== curLanguage)
    if (typeof document !== 'undefined') {
      hasReferrer = !(document.referrer == null || document.referrer.length === 0);
    }

    const actionPath = `/${curLanguage}/${pageContext.uid}/success`

  return (
    <Layout>
      <SEO title={data.page?.page_title || 'Betcenter'} description={data.page?.meta_description} lang={curLanguage} body={{'class': 'p-app'}} meta={appMeta} />
      {hasReferrer && (
        <div className="o-notice"><a href={"https://betcenter.be"}><img src={backIcon} alt="" /> {t('back')}</a></div>
      )}
        <Header current={curLanguage} languages={languages} />
        <div className="c-sections">
          {data.page.body.map(({__typename, primary, fields, label, type}, i)  => {
            switch (__typename) {
              case "PRISMIC_PageBodyForm":
                return <Form path={actionPath} primary={primary} success={location.pathname.endsWith('success')} key={i} fields={fields} />;
              case "PRISMIC_PageBodyShops":
                return <Shops language={pageContext.lang} key={i} primary={primary} />;
              case "PRISMIC_PageBodyHero":
                if (label === 'footer') {
                  return <CallToAction actions={primary.show_applinks} title={primary.title} key={i} />;
                }
                return <Hero label={label} images={{"ios": primary.image_ios, "android": primary.image_android, "default": primary.image_default}} actions={primary.show_applinks} title={primary.title} key={i} />;
              case "PRISMIC_PageBodyList":
                return <List label={label} items={fields} key={i} />;
              default: return null;
            }
          })}
        </div>
      <Footer disclaimer={data.page.disclaimer} language={curLanguage} />
    </Layout>
  )
}

export default IndexPage



