import React, { useEffect } from "react"
import { GoogleMap, Marker } from "@react-google-maps/api"
import marker from "../../images/marker.svg"

const styleMap = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
];

let mapRef = null;

const onMapLoaded = (map) => {
  mapRef = map;
  /*const bounds = new window.google.maps.LatLngBounds();
  markers.edges.forEach((item) => {
    bounds.extend({lng:item.node.geo.longitude, lat: item.node.geo.latitude});
  });

  map.fitBounds(bounds);*/
}

const ShopsMap = ({markers, current, onMarkerPress}) => {

  useEffect(() => {
    if (mapRef) {
      let position = {
        lat: current?.geo.lat || ShopsMap.defaultProps.center.lat,
        lng: current?.geo.lng || ShopsMap.defaultProps.center.lng
      };
      mapRef.panTo(position);
    }
  }, [current]);

  return (
    <GoogleMap
      onLoad={onMapLoaded}
      zoom={current ? 12 : 10}
      options={{styles: styleMap, disableDefaultUI: true}}
      mapContainerStyle={{height: '100%', width: '100%'}}
      mapContainerClassName={"o-map"}
      center={current ? null :  ShopsMap.defaultProps.center}
    >
      {markers.map((item, idx) =>
        <Marker onClick={() => onMarkerPress({geo: {lng: item.data.geo.longitude, lat: item.data.geo.latitude}, zip: item.data.zip, city: item.data.city})} icon={marker} key={idx} position={{lng:item.data.geo.longitude, lat: item.data.geo.latitude}} />
      )}
      { /* Child components, such as markers, info windows, etc. */ }
    </GoogleMap>
  )
}


ShopsMap.defaultProps = {
  center: {
    lat: 50.850346,
    lng: 4.351721
  }
}
export default ShopsMap;
